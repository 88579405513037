import { Company } from './types';

export const getCompanyEmailAndAdminEmail = (company: Company) => {
    const companyEmail = company.email;
    // @ts-ignore
    const myAdminEmail = company.myAdmin?.email;

    return {
        companyEmail,
        myAdminEmail,
    };
};
