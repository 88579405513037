import { ActionStructure, Avatar, FileSystemMapper, MC, Record, TypeNote } from '../../services/utils/types';

export const resolvedRefstockActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_REFSTOCK',
    fn: (refstock: Avatar) => ({
        type: resolvedRefstockActionCreator.action,
        payload: {
            refstock,
        },
    }),
};

export const resolvedContainerActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_CONTAINER',
    fn: (container: Avatar) => ({
        type: resolvedContainerActionCreator.action,
        payload: {
            container,
        },
    }),
};

export const resolvedMovementListActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_MOVEMENT_LIST',
    fn: (movementList: Avatar) => ({
        type: resolvedMovementListActionCreator.action,
        payload: {
            movementList,
        },
    }),
};

export const resolvedRefstockImage: ActionStructure = {
    action: '360SC/RESOLVED_REF_STOCK_IMAGE',
    fn: (record: FileSystemMapper) => ({
        type: resolvedRefstockImage.action,
        payload: {
            record,
        },
    }),
};

export const resolvedTypeNoteActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_TYPE_NOTE',
    fn: (typeNote: TypeNote) => ({
        type: resolvedTypeNoteActionCreator.action,
        payload: {
            typeNote,
        },
    }),
};

export const resolvedURIActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_URI',
    fn: (uri: string) => ({
        type: resolvedURIActionCreator.action,
        payload: {
            uri,
        },
    }),
};

export const resolvedRefstockPropertiesCreator: ActionStructure = {
    action: '360SC/RESOLVED_REFSTOCK_PROPERTIES',
    fn: (properties: any[]) => ({
        type: resolvedRefstockPropertiesCreator.action,
        payload: {
            properties,
        },
    }),
};

export const resolvedListOfMovementListActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_LIST_OF_MOVEMENT_LIST',
    fn: (movementLists: Avatar[]) => ({
        type: resolvedListOfMovementListActionCreator.action,
        payload: {
            movementLists,
        },
    }),
};

export const resolvedTokenWithRolesActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_TOKEN_WITH_ROLES',
    fn: (token: string) => ({
        type: resolvedTokenWithRolesActionCreator.action,
        payload: {
            token,
        },
    }),
};

export const resolvedMcActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_MC',
    fn: (mc: MC) => ({
        type: resolvedMcActionCreator.action,
        payload: {
            mc,
        },
    }),
};

export const resetResourcesActionCreator: ActionStructure = {
    action: '360SC/RESET_RESOURCES',
    fn: () => ({
        type: resetResourcesActionCreator.action,
        payload: {},
    }),
};
