import {
    ActionStructureFetcher,
    AmountAction,
    Avatar,
    AvatarProperty,
    EditRefStockCurrentAmountAction,
    Node,
    UpdateRefStockProcessAction,
} from '../../services/utils/types';

export const editRefStockCurrentAmountAction: ActionStructureFetcher = {
    action: '360SC/EDIT_REF_STOCK_CURRENT_AMOUNT',
    fn: (
        actionDescription: string,
        amountAction: AmountAction,
        amount,
        currentPickupAmount,
        howManyInStock,
        changeAmountProperty,
        meta: {
            onSuccess: (refStock: Avatar, movementList: Avatar, amountRefStockProperty: AvatarProperty) => void;
            onFailure: () => void;
        }
    ): EditRefStockCurrentAmountAction => ({
        type: editRefStockCurrentAmountAction.action,
        payload: {
            actionDescription,
            amountAction,
            amount,
            currentPickupAmount,
            howManyInStock,
            changeAmountProperty,
        },
        meta,
    }),
};

export const updateRefStockProcessAction: ActionStructureFetcher = {
    action: '360SC/UPDATE_REFSTOCK_PROCESS',
    fn: (
        refStock: Avatar,
        refStockProperties: AvatarProperty[],
        currentState: Node,
        meta: { dispatch: (nextNode: Node) => void }
    ): UpdateRefStockProcessAction => ({
        type: updateRefStockProcessAction.action,
        payload: {
            refStock,
            refStockProperties,
            currentState,
        },
        meta,
    }),
};

export const willMakeAnActionIntoTheContainerAction: ActionStructureFetcher = {
    action: '360SC/WILL_MAKE_AN_ACTION_INTO_THE_CONTAINER',
    fn: (
        container: Avatar,
        actionDescription: string,
        onSuccess: Function = () => {},
        onFailure: Function = () => {}
    ) => ({
        type: willMakeAnActionIntoTheContainerAction.action,
        payload: {
            container,
            actionDescription,
            onSuccess,
            onFailure,
        },
    }),
};

export const closeListOfMovementListAction: ActionStructureFetcher = {
    action: '360SC/CLOSE_LIST_OF_MOVEMENT_LIST',
    fn: (listOfMovementList: Avatar[], onSuccess: Function, onEachRequest?: (movementList: Avatar) => void) => ({
        type: closeListOfMovementListAction.action,
        payload: {
            listOfMovementList,
        },
        meta: {
            onSuccess,
            onEachRequest,
        },
    }),
};

export const sendEmailAction: ActionStructureFetcher = {
    action: '360SC/SEND_EMAIL',
    fn: (
        subject: string,
        addressesTo: string[],
        body: string,
        onSuccess?: Function,
        onFailure?: Function,
        attachments?: { fileName: string; data: string }[]
    ) => ({
        type: sendEmailAction.action,
        payload: {
            subject,
            addressesTo,
            body,
            attachments,
        },
        meta: {
            onSuccess,
            onFailure,
        },
    }),
};
