import { combineReducers } from 'redux';
import userReducer, {UserState} from "./userReducer";
import uiReducer, {UiState} from "./uiReducer";
import resourcesReducer, {ResourceState} from './resourcesReducer';

export interface GlobalState {
	user: UserState,
	ui: UiState,
	resources: ResourceState
}

const reducer = combineReducers({
	user: userReducer,
	ui: uiReducer,
	resources: resourcesReducer
})

export default reducer;