import Head from 'next/head';
import React, { useEffect } from 'react';

interface Props {
    title: any;
}

const HeadConf = ({ title }: Props) => {
    useEffect(() => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        function gtag() {
            // @ts-ignore
            dataLayer.push(arguments);
        }
        // @ts-ignore
        window.gtag = gtag;
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS);
    }, []);

    return (
        <Head>
            <title>{title}</title>
            <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
            <meta name='mobile-web-app-capable' content='yes' />
        </Head>
    );
};

export default HeadConf;
