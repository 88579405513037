import { Action } from 'redux';
import {
    editRefStockCurrentAmountAction,
    updateRefStockProcessAction,
} from '../../redux/actions/containerSecurityActionCreator';
import {
    crudCreateAction,
    crudGetListAction,
    crudUpdateAction,
    reqIdAction,
} from '../../redux/actions/fetchActionCreator';
import { loginAction } from '../../redux/actions/userActionCreator';
import {
    downloadFileSystemMapperAction,
    getOneFileSystemMapperAction,
} from '../../redux/actions/fileSystemMapperActionCreator';

export interface Sort {
    field: string;
    order: string;
}

export interface Pagination {
    page: number;
    perPage: number;
}

export type DataProvider = {
    getList: (resource: string, params: GetListParams) => Promise<GetListResult>;

    getOne: (resource: string, params: GetOneParams) => Promise<GetOneResult>;

    create: (resource: string, params: CreateParams) => Promise<CreateResult>;

    update: (resource: string, params: UpdateParams) => Promise<UpdateResult>;

    delete: (resource: string, params: DeleteParams) => Promise<DeleteResult>;

    getOneFileSystemMapper: (resource: string, params: GetFileSystemMapperParams) => Promise<GetFileSystemMapperResult>;

    downloadFileSystemMapper: (resource: string, params: { id: string }) => Promise<string>;

    [key: string]: any;
};

export interface RequestPayload {
    pagination: Pagination;
    sort: Sort;
    filter: object;
}

export interface GetListParams {
    pagination: Pagination;
    sort: Sort;
    filter: any;
}

export interface GetListResult {
    data: Record[];
    total: number;
}

export interface GetOneParams {
    id: Identifier;
}

export interface Record {
    id: Identifier;
    '@id': string;

    [key: string]: any;
}

export type Identifier = string | number;

export interface GetOneResult {
    data: Record;
}

export interface CreateParams {
    data: any;
}

export interface CreateResult {
    data: Record;
}

export interface UpdateParams {
    id: Identifier;
    data: any;
    previousData: Record;
}

export interface UpdateResult {
    data: Record;
}

export interface DeleteParams {
    id: Identifier;
}

export interface GetFileSystemMapperParams {
    lvl: number;
    isFolder: boolean;
    ['parent.parent.name']: string;
    ['parent.name']: string;
    name: string;
}

export interface DeleteResult {
    data?: Record;
}

export interface GetFileSystemMapperResult {
    data?: Record;
}

export type AuthProvider = {
    login: (params: any) => Promise<any>;
};

// > Any action structure
export interface ActionStructureAction {
    readonly type: string;
    readonly payload: any;
    readonly meta?: any;
}

export interface ActionStructure {
    action: string;
    fn: (...args: any[]) => ActionStructureAction;
}

// <

// any action using fetcher
export interface ActionStructureFetcher extends ActionStructure {
    action: string;
    fn: (...args: any[]) => any;
}

export interface FetcherRequestAction extends ActionStructureAction {
    readonly type: string;
    readonly payload: any;
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
        [key: string]: any;
    };
}

export interface GeneralRequestAction {
    readonly type: typeof reqIdAction.action;
    readonly payload: {
        reqId: string;
        action: FetcherRequestAction;
    };
}

export interface SuccessPayload {
    total: number;
    data: any | any[];
}

export interface Callbacks {
    onSuccess?: (payload: SuccessPayload, requestPayload: any) => any | null | Action | Action[];
    onFailure?: (
        payload: {
            err: {
                config?: object;
                request?: object;
                response?: {
                    data: object;
                    status: number;
                };
            };
        },
        requestPayload: object
    ) => any;
    onTerminating?: (payload: object, requestPayload: object, isSuccess: boolean) => any | null | Action | Action[];
}

export interface CrudGetListAction extends FetcherRequestAction {
    readonly type: typeof crudGetListAction.action;
    readonly payload: RequestPayload;
}

export interface ReqIdCrudGetListActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        resource: string,
        pagination: Pagination,
        sort: Sort,
        filter: object,
        callbacks: Callbacks | undefined
    ) => GeneralRequestAction;
}

export interface CrudCreateAction extends FetcherRequestAction {
    readonly type: typeof crudCreateAction.action;
    readonly payload: {
        data: any;
    };
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
    };
}

export interface ReqIdCrudCreateActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        data: any,
        resource: string,
        callbacks: Callbacks | undefined
    ) => {
        readonly type: typeof reqIdAction.action;
        payload: {
            reqId: string;
            action: CrudCreateAction;
        };
    };
}

export interface CrudUpdateAction extends FetcherRequestAction {
    readonly type: typeof crudUpdateAction.action;
    readonly payload: {
        data: any;
        id: Identifier;
        previousData: any;
    };
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
    };
}

export interface ReqIdCrudUpdateActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        id: Identifier,
        resource: string,
        data: any,
        previousData: any,
        callbacks: Callbacks | undefined
    ) => {
        readonly type: typeof reqIdAction.action;
        payload: {
            reqId: string;
            action: CrudUpdateAction;
        };
    };
}

export interface EditRefStockCurrentAmountAction extends ActionStructureAction {
    type: typeof editRefStockCurrentAmountAction.action;
    payload: {
        actionDescription: string;
        amountAction: AmountAction;
        amount: number;
        currentPickupAmount: number;
        howManyInStock: number;
        changeAmountProperty: boolean;
    };
    meta: {
        onSuccess: (refStock: Avatar, movementList: Avatar, amountRefStockProperty: AvatarProperty) => void;
        onFailure: () => void;
    };
}

export interface GetOnFileSystemMapperAction extends ActionStructureAction {
    type: typeof getOneFileSystemMapperAction.action;
    payload: {
        data: GetFileSystemMapperParams;
    };
    meta: {
        onSuccess: ({ data }: { data: Record }) => void;
        onFailure: () => void;
    };
}

export interface DownloadFileSystemMapperAction extends ActionStructureAction {
    type: typeof downloadFileSystemMapperAction.action;
    payload: {
        id: string;
    };
    meta: {
        onSuccess: ({ data }: { data: string }) => void;
        onFailure: () => void;
    };
}

export interface UpdateRefStockProcessAction extends ActionStructureAction {
    type: typeof updateRefStockProcessAction.action;
    payload: {
        refStock: Avatar;
        refStockProperties: AvatarProperty[];
        currentState: Node;
    };
    meta: {
        dispatch: (nextNode: Node) => void;
    };
}

export interface LoginAction extends FetcherRequestAction {
    readonly type: typeof loginAction.action;
    readonly payload: { username: string; password: string };
}

export enum ReqStatus {
    PENDING = 'PENDING',
    FAILURE = 'FAILURE',
    DONE = 'DONE',
}

export interface MC extends Record {
    id: string;
    idReference: string;
    name: string;
    avatar: string;
    company: string;
    yourls: {
        id: string;
        short: string;
        mcUrl: string;
    };
    creationDate: Date;
    pairedUp: boolean;
    enabled: boolean;
}

export interface CurrentReferencing extends Record {
    standardReferencing: {
        runner: {
            node: Node;
            [key: string]: any;
        };
        [key: string]: any;
    };
}

export interface Node {
    id: string;
    name: string;
    extraDataClassmapper: string;
    extraData: any;
    dateOfTakesPlace: string;
    iconId: string;
    nextNodes: any[];
}

export interface MetadataAvatarTypeProperty extends Record {
    id: string;
    name: string;
    values: any[];
    private: boolean;
    definition: string;
    timestamp: number;
    createdAt: Date;
    metadataAvatarType: string;
    isEncrypted: boolean;
    fieldType: string;
    order: number;
    option: string;
    required: boolean;
}

export interface MetadataAvatarType extends Record {
    id: string;
    name: string;
    alphaId: string;
    description: string;
    groupMetadataAvatarType: string;
    editable: boolean;
}

export interface Avatar extends Record {
    id: string;
    name: string;
    mcs: MC[] | string[];
    metadataAvatarType: MetadataAvatarType;
    company?: Company;
    postalCode: string;
    alphaId: string;
    creationDate: string;
    currentReferencing: {
        standardReferencing: {
            [key: string]: any;
            runner: {
                node: {
                    name: string;
                    dateOfTakesPlace: string;
                    nodeTypes: string[];
                    nextNodes: any[];
                    [key: string]: any;
                };
                firstNode: {
                    name: string;
                    dateOfTakesPlace: string;
                    nodeTypes: string[];
                    nextNodes: any[];
                    [key: string]: any;
                };
            };
        };
    };
    parent: Avatar | string;
    images: any[] | null;
}

export interface AvatarProperty extends Record {
    name: string;
    value: any;
}

export interface TraceabilityNoteDialog {
    open: boolean;
    mode?: string;
    onClose: Function;
    changeTraceability: (data: any, toUpdateAvatar?: string | null, node?: any) => void;
    node:
        | {
              id: string;
              name: string;
              configuration: {
                  customFields: { [key: string]: any };
              };
          }
        | any;
    zoneConfig?: object;
    avatar?: {
        company?: {
            name?: string;
        };
        id?: string;
    };
    isAnonymous?: boolean;
    user?: {
        firstName: string;
        lastName: string;
    };
}

export interface TokenWithRoles {
    token: string;
    refreshToken: string;
}

export interface TypeNote {
    [key: string]: any;
}

export enum GroupMetadataAvatarTypeCustomFieldUrlType {
    GDRIVE = 'gdrive',
}
export interface GroupMetadataAvatarTypeCustomFieldUrl {
    url: string;
    type: GroupMetadataAvatarTypeCustomFieldUrlType;
}

export interface GroupMetadataAvatarType {
    id: string;
    name: string;
    company: string;
    customFields: {
        user_guide?: GroupMetadataAvatarTypeCustomFieldUrl;
        routine_maintenance?: GroupMetadataAvatarTypeCustomFieldUrl;
        technical_sheet?: GroupMetadataAvatarTypeCustomFieldUrl;
    };
}

export interface Company extends Record {
    name: string;
    identifiers: string[] | null;
    '@id': string;
    [key: string]: any;
    email?: string;
    myAdmin:
        | string
        | {
              id: string;
              email: string;
          };
}

export interface Media extends Record {
    name: string;
    '@id': string;
    source: string;
    customFields: {
        resourceSupported: string;
    };
    contentUrlTokenAccess: string;
}

export interface FileSystemMapper extends Record {
    name: string;
    '@id': string;
    id: string;
    companyId: string;
    isEditable: boolean;
    isFolder: boolean;
    metadata: any;
    parent: FileSystemMapper;
    tags: string[];
}

export enum AmountAction {
    ACTION_INCREASE = 'ACTION_INCREASE',
    ACTION_SET_ANOMALLY = 'ACTION_SET_ANOMALLY',
    ACTION_DECREASE = 'ACTION_DECREASE',
}

export interface Note extends Record {}
