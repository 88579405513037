import { createTheme } from '@mui/material';

const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#3F51B5',
        },
    },
});

export default mainTheme;
