import { all, call, spawn } from 'redux-saga/effects';
import { Services } from '../store';
import containerRequest from './containerSecurityMiddleware';
import fileSystemMapperMiddleware from './fileSystemMapperMiddleware';
import makeRequest from './makeRequest';

export default (services: Services) =>
    function* rootSaga() {
        const sagas = [
            makeRequest(services.dataProvider, services.authProvider),
            containerRequest(services.dataProvider),
            fileSystemMapperMiddleware(services.dataProvider),
        ];

        yield all(
            sagas.map((saga, index: number) =>
                spawn(function* () {
                    while (true) {
                        try {
                            // @ts-ignore
                            yield call(saga);
                            break;
                        } catch (e) {
                            console.log('Index in saga ', index);
                            console.log('RootSaga error ', e);
                        }
                    }
                })
            )
        );
    };
