import {
    ActionStructure,
    ActionStructureFetcher,
    Callbacks,
    GeneralRequestAction,
    LoginAction,
} from '../../services/utils/types';
import { reqIdAction } from './fetchActionCreator';

export const loginAction: ActionStructureFetcher = {
    action: '360SC/LOGIN',
    fn: (username: string, password: string, callbacks: Callbacks): LoginAction => ({
        type: loginAction.action,
        payload: {
            username,
            password,
        },
        meta: {
            resource: 'LOGIN',
            callbacks,
        },
    }),
};

export interface LoginReqIdActionStructureFetcher extends ActionStructureFetcher {
    fn: (reqId: string, username: string, password: string, callbacks: Callbacks) => GeneralRequestAction;
}

export const loginReqIdAction: LoginReqIdActionStructureFetcher = {
    action: reqIdAction.action,
    fn: (reqId: string, username: string, password: string, callbacks: Callbacks): GeneralRequestAction => ({
        type: loginReqIdAction.action,
        payload: {
            reqId,
            action: loginAction.fn(username, password, callbacks),
        },
    }),
};

export const resolvedTokenAction: ActionStructure = {
    action: '360SC/RESOLVED_TOKEN',
    fn: (token: string, refreshToken: string | null = null) => ({
        type: resolvedTokenAction.action,
        payload: {
            token,
            refreshToken,
        },
    }),
};

export const removeTokenAction: ActionStructure = {
    action: '360SC/REMOVE_TOKEN',
    fn: () => ({
        type: removeTokenAction.action,
        payload: {},
    }),
};

export const logoutAction: ActionStructureFetcher = {
    action: '360SC/LOGOUT',
    fn: () => ({
        type: logoutAction.action,
    }),
};
