import { put, select, takeEvery } from 'redux-saga/effects';

import {
    DataProvider,
    DownloadFileSystemMapperAction,
    GetFileSystemMapperResult,
    GetOnFileSystemMapperAction,
} from '../../services/utils/types';

import { downloadFileSystemMapperAction, getOneFileSystemMapperAction } from '../actions/fileSystemMapperActionCreator';
import { FILE_SYSTEM_MAPPERS } from '../../services/utils/CONST';
import { resolvedRefstockImage } from '../actions/resourceActionCreator';

const getOneFileSystemMapperMiddleware = (dataProvider: DataProvider) =>
    function* ({ payload: { data }, meta }: GetOnFileSystemMapperAction) {
        const response: GetFileSystemMapperResult = yield dataProvider.getOneFileSystemMapper(
            FILE_SYSTEM_MAPPERS,
            data
        );

        if (response && 'data' in response && response.data) {
            meta.onSuccess({ data: response.data });
            yield put(resolvedRefstockImage.fn(response.data));
        } else {
            meta.onFailure();
        }
    };

const downloadFileSystemMapperMiddleware = (dataProvider: DataProvider) =>
    function* ({ payload: { id }, meta }: DownloadFileSystemMapperAction) {
        // @ts-ignore
        const imageData: any = yield dataProvider.downloadFileSystemMapper(FILE_SYSTEM_MAPPERS, { id });

        meta.onSuccess({ data: imageData });
    };

export default (dataProvider: DataProvider) => {
    return function* runRequest() {
        yield takeEvery(getOneFileSystemMapperAction.action, getOneFileSystemMapperMiddleware(dataProvider));
        yield takeEvery(downloadFileSystemMapperAction.action, downloadFileSystemMapperMiddleware(dataProvider));
    };
};
