const FINGER_TOKEN_PREFIX = process.env.NEXT_PUBLIC_FINGER_TOKEN_PREFIX;
const MC_AUTO_AUTH_TOKEN_PREFIX = process.env.NEXT_PUBLIC_MC_AUTO_AUTH_TOKEN_PREFIX;
const FINGER_UNIQUE_VALUE = process.env.NEXT_PUBLIC_FINGER_UNIQUE_VALUE;

export const MC_NOTES_PATH = 'm_c_notes';
export const TYPE_NOTES = 'type_notes';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const SIMULATE_MC_GENERATOR = 'simulate_m_c_generator';
export const SIMULATE_AND_CREATE_MC_GENERATOR = 'simulate_and_create_m_c_generator';
export const FINGER_ID_FOR_CREATE_MC = '53ad7037-b20f-11ea-9cf3-00505692e487';
export const MCS_CROSS_STOCK = 'm_cs/cross-stock';
export const AVATAR_TRACEABILITY = 'traceability/current_referencing';
export const AVATARS = 'avatars';
export const IMAGES = 'image';
export const FILES = 'files';
export const MEDIA = 'media';
export const TRACEABILITY_TRACKING = 'traceability/tracking';
export const COMPANIES = 'companies';
export const FINGERS = 'fingers';
export const AVATAR_PROPERTIES = 'avatar_properties';
export const TOKEN_WITH_ROLES = 'token-connected';
export const SEND_EMAILS = 'send_emails';
export const FILE_SYSTEM_MAPPERS = 'file_system_mappers';

export const UNIQUE_IDENTIFIER_BY_FINGER = FINGER_UNIQUE_VALUE;
export const METADATA_AVATAR_TYPE_EQUALPROCESSDEFNITION_MAPPER_MEMOTAG_DATA = 'MEMOTAGPRO';

export const MEDIA_FILE_TYPE = 'FILE';
export const MEDIA_IMAGE_TYPE = 'IMAGE';
export const MEDIA_LINK_TYPE = 'LINK';
export const MEDIA_AUDIO_TYPE = 'AUDIO';
export const RESOURCE_SUPPORTED_MEDIAS = 'avatar';
export const UPLOAD_IMAGES = 'medias';

export const NOTE_MEDIA_FILE = 'File';
export const NOTE_MEDIA_AUDIO = 'Audio';
export const NOTE_MEDIA_IMAGE = 'Image';

export const AUTHENTICATION_TYPE = 'Bearer';

export const MAIN_PAGE = '/main';
export const ROOT = '/';
export const CREATE_AVATAR_PAGE = '/create-avatar';
export const LOGIN_PAGE = '/login';
export const LEVEL_PAGE = '/level';
export const ABOUT_PAGE = '/about';
export const REDIRECT_PAGE = '/redirect';
export const CREATE_MOVEMENT_LIST_PAGE = '/create-movement-list';
export const REFSTOCK_PAGE = '/refstock';
export const RESTOCKING_PAGE = '/restocking';
export const CONTAINER_PAGE = '/container';
export const FENCING_MOVEMENT_LIST_PAGE = '/fencing-movement-list';
export const FINISH_PAGE = '/finish';
export const MOVEMENT_LIST_PAGE = '/movement-list';
export const DEPOSIT_PAGE = '/deposit';
export const CATALOG_PAGE = '/catalog';
export const EVALUATION_PAGE = '/evaluation';

// Modes

export const TOKEN_PARTS_PREFIX = `${FINGER_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_SIZE = `${FINGER_TOKEN_PREFIX}-tokenPartsSize`;

export const TOKEN_PARTS_MC_AUTO_AUTH_PREFIX = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_MC_AUTO_AUTH_SIZE = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPartsSize`;

export const TOKEN_MAX_PARTS_SIZE = 2000;
export const LEVEL_FOLDER_IN_FILE_SYSTEM_MAPPER_SERVICE = 5;
export const AVATAR_FOLDER_PREFIX_IN_FILE_SYSTEM_MAPPER_SERVICE = 'sc:folder-avatar:';
export const SYSTEM_FOLDER_NAME_IN_FILE_SYSTEM_MAPPER_SERVICE = 'system';

export const LOGIN_ANONYMOUS = 'optimized_token_semi_anonymous_easy_login_check';

export const LOGIN_OPTIMIZED_TOKEN = 'optimized_token_login_check';

// export const REF_STOCK_OBJECT_TYPE_ALPHA_ID = process.env.NEXT_PUBLIC_REF_STOCK_OBJECT_TYPE_ALPHA_ID;
export const REF_STOCK_OBJECT_TYPE_ALPHA_ID = 'stock';
// export const MOVEMENT_LIST_OBJECT_TYPE_ALPHA_ID = process.env.NEXT_PUBLIC_MOVEMENT_LIST_OBJECT_TYPE_ALPHA_ID;
export const MOVEMENT_LIST_OBJECT_TYPE_ALPHA_ID = 'movement-list';
// export const CONTAINER_OBJECT_TYPE_ALPHA_ID = process.env.NEXT_PUBLIC_CONTAINER_OBJECT_TYPE_ALPHA_ID;
export const CONTAINER_OBJECT_TYPE_ALPHA_ID = 'container';
// export const DEPOSIT_OBJECT_TYPE_ALPHA_ID = process.env.NEXT_PUBLIC_DEPOSIT_OBJECT_TYPE_ALPHA_ID;
export const DEPOSIT_OBJECT_TYPE_ALPHA_ID = '...';

export const OPEN_STATE = 'Ouverte';
export const QRCODE = 'QRCODE';

export const AMOUNT_KEY = 'amount';
export const LONG_TEXT_KEY = 'long text product';
export const CODE_BRAVO_KEY = 'code bravo';
export const BRAVE_CODE = 'code bravo';
export const FULL_AMOUNT = 'amount planned';
export const MINIMUM_AMOUNT = 'amount minimum';

export const GLOBAL_NOTE = 'GlobalNote';

export const PICKUP_WELL = 'Prélèvement';
export const PICKUP_INCONSISTENT_STOCK = 'Prélèvement - Stock Incohérant';
export const OVER_PICKUP = 'Dépasse Prélèvement';
export const OVER_PICKUP_INCONSISTENT_STOCK = 'Dépasse Prélèvement - Stock Incohérant';

export const RESTOCK_WELL = 'Réassort';
export const RESTOCK_INCONSISTENT_STOCK = 'Réassort et correction de stock';
export const AUDIT_WELL = 'Stock juste';
export const AUDIT_INCONSISTENT_STOCK = 'Correction de stock';

export const OPEN_PROBLEM_CONTAINER = 'Containeur ouverture annormale';
export const LINKED_NOTE_TITLE = '__LINKED_NOTE__';
export const EVALUATION_NOTE_TITLE = 'Evaluation';
export const PROCEDURE_NOTE_TITLE = 'Procédure de fermeture';

export const EmailMessageMapper = {
    [PICKUP_INCONSISTENT_STOCK]: 'ANOMALIE DE STOCK RESTANT APRES PRELEVEMENT',
    [`${PICKUP_INCONSISTENT_STOCK}_PK`]: 'Un désaccord sur la quantité restante en stock',
    [OVER_PICKUP_INCONSISTENT_STOCK]: 'PRELEVEMENT SUPERIEUR A LA QUANTITE THEORIQUE ET DESACCORD SUR LE STOCK RESTANT',
    [`${OVER_PICKUP_INCONSISTENT_STOCK}_PK`]:
        'Quantité prélevée supérieure à la quantité théorique et désaccord sur la quantité restante',
    [OVER_PICKUP]: 'PRELEVEMENT SUPERIEUR A LA QUANTITE THEORIQUE',
};

export const FULL_STATE = 'plein';
export const STARTED_STATE = 'entamé';
export const ALERT_STATE = 'alerte';
export const EMPTY_STATE = 'vide';

export const CONTAINER_OPEN_STATE = 'Ouvert';
export const CONTAINER_CLOSED_STATE = 'Fermé';
export const CONTAINER_OPEN_ABNORMALLY_STATE = 'Ouvert Annormal';

export const MOVEMENT_LIST_CLOSE_STATE = 'fermée';
export const MOVEMENT_LIST_FENCE_STATE = 'Clôturée';
export const MOVEMENT_LIST_OPEN_STATE = 'Ouverte';
export const MOVEMENT_LIST_OPEN_ABNORMALLY_STATE = 'Ouvert Annormal';

export const CLOSING_PROCEDURE = 'procédure de fermeture';

export const ROLE_CLOSE_MOVEMENT_LIST = 'ROLE_CLOSE_MOVEMENT_LIST';
export const ROLE_CREATE_MOVEMENT_LIST = 'ROLE_CREATE_MOVEMENT_LIST';
export const ROLE_CLOSE_CONTAINER = 'ROLE_CLOSE_CONTAINER';
export const ROLE_PREPARATION_CONTAINER = 'ROLE_PREPARATION_CONTAINER';
export const ROLE_OPEN_CONTAINER = 'ROLE_OPEN_CONTAINER';

export const ROLE_CHRISTAUD_MANAGER = 'ROLE_CHRISTAUD_MANAGER';
export const ROLE_CHRISTAUD_ADMIN = 'ROLE_CHRISTAUD_ADMIN';
export const ROLE_CHRISTAUD_OPERATOR = 'ROLE_CHRISTAUD_OPERATOR';
export const ROLE_CHRISTAUD_DELIVERY_MAN = 'ROLE_CHRISTAUD_DELIVERY_MAN';
export const ROLE_CHRISTAUD_COMMERCIAL = 'ROLE_CHRISTAUD_COMMERCIAL';
export const ROLE_CHRISTAUD_OPERATOR_MINUTE = 'ROLE_CHRISTAUD_OPERATOR_MINUTE';

export const RESTOCKING_ROLES = [
    ROLE_CHRISTAUD_MANAGER,
    ROLE_CHRISTAUD_ADMIN,
    ROLE_CHRISTAUD_OPERATOR,
    ROLE_CHRISTAUD_DELIVERY_MAN,
    ROLE_CHRISTAUD_COMMERCIAL,
    ROLE_CHRISTAUD_OPERATOR_MINUTE,
];
