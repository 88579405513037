import moment from 'moment';

export const toFormatDate = (stringDate: string | Date, momentFormat = 'DD/MM/YYYY  k:mm') => {
    let date = moment(stringDate);
    return date.format(momentFormat);
};

const getStringNow = (): string => toFormatDate(new Date());

export { getStringNow };
