import { getMcUri } from '../components/utils';
import { getCompanyEmailAndAdminEmail } from './utils/company';
import {
    ALERT_STATE,
    AMOUNT_KEY,
    AVATAR_PROPERTIES,
    AVATAR_TRACEABILITY,
    AVATARS,
    CONTAINER_OPEN_ABNORMALLY_STATE,
    CONTAINER_OPEN_STATE,
    EMPTY_STATE,
    FULL_AMOUNT,
    FULL_STATE,
    LINKED_NOTE_TITLE,
    LONG_TEXT_KEY,
    MC_NOTES_PATH,
    MINIMUM_AMOUNT,
    MOVEMENT_LIST_CLOSE_STATE,
    MOVEMENT_LIST_OPEN_STATE,
    SEND_EMAILS,
    STARTED_STATE,
    TRACEABILITY_TRACKING,
} from './utils/CONST';
import { extractIdFromURI, getResourceURIFROMAnotherURI } from './utils/ids';
import { getNodes } from './utils/process';
import {
    AmountAction,
    Avatar,
    AvatarProperty,
    Company,
    DataProvider,
    Identifier,
    Node,
    Note,
    TypeNote,
} from './utils/types';
import { UserState } from '../redux/reducers/userReducer';
import { getStringNow } from './utils/date';

export const editAmountItem = async (
    dataProvider: DataProvider,
    actionDescription: string,
    amountAction: AmountAction,
    amount: number,
    currentPickupAmount: number,
    refStock: Avatar,
    refStockProperties: AvatarProperty[],
    movementList: Avatar,
    typeNote: TypeNote,
    howManyInStock: number,
    // @deprecated
    changeAmountProperty: boolean = true,
    {
        onSuccess,
        onFailure,
    }: {
        onSuccess: (refStock: Avatar, movementList: Avatar, amountRefStockProperty: AvatarProperty) => void;
        onFailure: Function;
    }
) => {
    const amountRefstockProperty = refStockProperties.find((item) => item.name === AMOUNT_KEY);
    const longTextRefstockProperty = refStockProperties.find((item) => item.name === LONG_TEXT_KEY);

    let response = null;
    if (changeAmountProperty) {
        // @ts-ignore
        response = await updateRefStockAmount(dataProvider, amountRefstockProperty, amount);
    }

    const promiseNote: Promise<Note | null> = createNote(
        dataProvider,
        refStock,
        typeNote['@id'],
        actionDescription,
        `${refStock.id} :: ${refStock.alphaId} :: ${refStock.name} :: ${
            longTextRefstockProperty ? longTextRefstockProperty.value : ''
        } :: ${amountAction} :: ${currentPickupAmount} :: ${amount} :: ${howManyInStock} :: ${
            movementList ? movementList.id : ''
        }`
    );

    if (movementList) {
        promiseNote.then((note: Note | null) => {
            if (note) {
                createNote(dataProvider, movementList, typeNote['@id'], `${LINKED_NOTE_TITLE}-${note.id}`, '');
            }
        });
    }

    if ((response && 'data' in response) || response === null) {
        // @ts-ignore
        onSuccess(refStock, movementList, response ? response.data : null);
    } else {
        onFailure();
    }
};

const createNote = async (
    dataProvider: DataProvider,
    refStock: Avatar,
    typeNoteUri: string,
    title: string,
    content: string
): Promise<Note | null> => {
    const mcUri = refStock.mcs.length > 0 ? getMcUri(refStock.mcs[0]) : null;

    const response = await dataProvider.create(MC_NOTES_PATH, {
        data: {
            // @ts-ignore
            itemId: extractIdFromURI(mcUri),
            data: {
                customFields: {
                    forms: {
                        _note_: {
                            content,
                            title,
                        },
                    },
                },
            },
            typeNote: typeNoteUri,
        },
    });

    if (response && 'data' in response) {
        return response.data;
    }

    return null;
};

// 1; 6
// 1; 7; 2

const updateTraceability = async (
    dataProvider: DataProvider,
    id: Identifier,
    resource: string,
    data: any,
    previousData: any = null
) => dataProvider.update(resource, { id, data, previousData });

const updateRefStockAmount = (dataProvider: DataProvider, amountRefstockProperty: AvatarProperty, amount: number) =>
    dataProvider.update(AVATAR_PROPERTIES, {
        id: amountRefstockProperty['@id'],
        data: { ...amountRefstockProperty, value: String(amount) },
        previousData: amountRefstockProperty,
    });

export const updateRefStockProcess = (
    dataProvider: DataProvider,
    refStock: Avatar,
    refStockProperties: AvatarProperty[],
    currentState: Node,
    meta: { dispatch: (nextNode: Node) => void }
) => {
    // @ts-ignore
    const amount: AvatarProperty = refStockProperties.find((prop) => prop.name === AMOUNT_KEY);
    // @ts-ignore
    const minimumAmount: AvatarProperty = refStockProperties.find((prop) => prop.name === MINIMUM_AMOUNT);
    // @ts-ignore
    const fullAmount: AvatarProperty = refStockProperties.find((prop) => prop.name === FULL_AMOUNT);
    // @ts-ignore
    const nodes = getNodes(refStock?.currentReferencing.standardReferencing.runner.firstNode);

    if (!amount || !minimumAmount || !fullAmount) return;

    console.log('Amount', amount.value);
    console.log('Minimum', minimumAmount.value);
    console.log('Full', fullAmount.value);
    console.log('CurrentState', currentState.name);

    if (
        Number(amount.value) >= Number(fullAmount.value) &&
        currentState.name.toUpperCase() !== FULL_STATE.toUpperCase()
    ) {
        const node = nodes.find((node) => node.name.toUpperCase() === FULL_STATE.toUpperCase());

        if (node) {
            console.log(node.name);
            meta.dispatch(node);
        }
    } else if (
        Number(amount.value) < Number(fullAmount.value) &&
        Number(amount.value) > Number(minimumAmount.value) &&
        currentState.name.toUpperCase() !== STARTED_STATE.toUpperCase()
    ) {
        const node = nodes.find((node) => node.name.toUpperCase() === STARTED_STATE.toUpperCase());

        if (node) {
            console.log(node.name);
            meta.dispatch(node);
        }
    } else if (
        Number(amount.value) <= Number(minimumAmount.value) &&
        Number(amount.value) > Number(0) &&
        currentState.name.toUpperCase() !== ALERT_STATE.toUpperCase()
    ) {
        const node = nodes.find((node) => node.name.toUpperCase() === ALERT_STATE.toUpperCase());

        if (node) {
            console.log(node.name);
            meta.dispatch(node);
        }
    } else if (Number(amount.value) <= Number(0) && currentState.name.toUpperCase() !== EMPTY_STATE.toUpperCase()) {
        const node = nodes.find((node) => node.name.toUpperCase() === EMPTY_STATE.toUpperCase());

        if (node) {
            console.log(node.name);
            meta.dispatch(node);
        }
    }
};

export const checkContainerState = async (
    dataProvider: DataProvider,
    container: Avatar,
    movementList: Avatar,
    refStock: Avatar,
    typeNote: TypeNote,
    actionDescription: string,
    userState: UserState,
    onSuccess: Function,
    onFailure: Function
) => {
    // @ts-ignore
    const nodes = getNodes(container?.currentReferencing.standardReferencing.runner.firstNode);
    const nextNode = nodes.find((node) => node.name === CONTAINER_OPEN_ABNORMALLY_STATE);

    let isOk = true;

    if (
        container.currentReferencing.standardReferencing.runner.node.name != CONTAINER_OPEN_STATE &&
        container.currentReferencing.standardReferencing.runner.node.name != CONTAINER_OPEN_ABNORMALLY_STATE &&
        nextNode
    ) {
        /**
         * The notification is configured in the process
         */
        updateTraceability(
            dataProvider,
            `${getResourceURIFROMAnotherURI(
                container['@id'],
                // @ts-ignore
                container?.currentReferencing.standardReferencing['id'],
                TRACEABILITY_TRACKING
            )}/changenode`,
            AVATAR_TRACEABILITY,
            {
                next: true,
                node: {
                    id: nextNode.id,
                    extraData: {},
                },
            }
        );

        const notePromise: Promise<Note | null> = createNote(
            dataProvider,
            container,
            typeNote['@id'],
            actionDescription,
            `${refStock.id} :: ${refStock.alphaId} :: ${refStock.name} :: _ :: _ :: _`
        );

        if (movementList) {
            notePromise.then((note: Note | null) => {
                if (note) {
                    createNote(dataProvider, movementList, typeNote['@id'], `${LINKED_NOTE_TITLE}-${note.id}`, '');
                }
            });
        }

        if (refStock.company) {
            const emails = getCompanyEmailAndAdminEmail(refStock.company);
            const addresses = [emails.companyEmail, emails.myAdminEmail, 'jorge-luis@360sc.io'].filter((f) => !!f);

            let body = `
                <strong>Pour le conteneur:</strong> ${container?.name} <br>
                <strong>Anomalie sur l'ouverture du conteneur</strong>    
                <strong>Anomalie d'ouverture par</strong>: <br>
                <strong>Utilisateur</strong>: ${userState.tokenData?.payload.username}, ${
                userState.tokenData?.payload.email
            } (${userState.tokenData?.payload.user_id}) <br>
                <strong>À la date de</strong>: ${getStringNow()} <br>
                `;

            sendEmailRequest(
                dataProvider,
                `Dans le conteneur ${container?.name}. Anomalie sur l'ouverture du conteneur`,
                addresses,
                body
            );
        }
    }

    if (movementList) {
        const response = await getMovementList(dataProvider, movementList);
        if (response && 'data' in response && response.data.length > 0) {
            const currentNode = response.data[0].currentReferencing.standardReferencing.runner.node;
            isOk = currentNode.name.toLowerCase() === MOVEMENT_LIST_OPEN_STATE.toLowerCase();
        }
    }

    if (isOk) {
        onSuccess();
    } else {
        onFailure();
    }
};

export const closeListOfMovementList = async (
    dataProvider: DataProvider,
    movementLists: Avatar[],
    container: Avatar,
    company: Company,
    userState: UserState,
    onSuccess: Function = () => {},
    onEachRequest?: (movementList: Avatar) => void
) => {
    for (const movementList of movementLists) {
        // @ts-ignore
        const nodes = getNodes(movementList.currentReferencing.standardReferencing.runner.firstNode);
        // @ts-ignore
        const closeState: Node = nodes.find(
            (node) => node.name.toLowerCase() === MOVEMENT_LIST_CLOSE_STATE.toLowerCase()
        );

        await updateTraceability(
            dataProvider,
            `${getResourceURIFROMAnotherURI(
                movementList['@id'],
                // @ts-ignore
                movementList?.currentReferencing.standardReferencing['id'],
                TRACEABILITY_TRACKING
            )}/changenode`,
            AVATAR_TRACEABILITY,
            {
                next: true,
                node: {
                    id: closeState.id,
                    extraData: {},
                },
            }
        );

        if (onEachRequest) {
            onEachRequest(movementList);
        }
    }

    if (movementLists && movementLists.length > 0) {
        const emails = getCompanyEmailAndAdminEmail(company);
        const addresses = [emails.companyEmail, emails.myAdminEmail].filter((f) => !!f);

        let body = `
            <strong>Pour le conteneur:</strong> ${container?.name} <br>
            <strong>Signalement d'une fermeture forcée des listes de mouvements</strong> <br>
            <strong>Utilisateur</strong>: ${userState.tokenData?.payload.username}, ${
            userState.tokenData?.payload.email
        } (${userState.tokenData?.payload.user_id}) <br>
            <strong>À la date de</strong>: ${getStringNow()} <br>
            Liste de movement fermées: <br>
                `;
        movementLists.forEach((movementList) => {
            body = `
                ${body}
                Id: ${movementList.id}, Nom: ${movementList.name} <br>
                `;
        });

        await sendEmailRequest(
            dataProvider,
            `Dans le conteneur ${container?.name}. Signalement d'une fermeture forcée des listes de mouvements`,
            addresses,
            body
        );
    }

    onSuccess();
};

export const sendEmailRequest = async (
    dataProvider: DataProvider,
    subject: string,
    addressesTo: string[],
    body: string,
    onSuccess?: Function,
    onFailure?: Function,
    attachments?: { fileName: string; data: string }[]
) => {
    const stringBody = `<html><body>${body}</body></html>`;

    const response = await dataProvider.create(SEND_EMAILS, {
        data: {
            subject,
            addressesTo,
            body: btoa(unescape(encodeURIComponent(stringBody))),
            ...(attachments ? { attachments } : {}),
        },
    });

    if (response && 'data' in response) {
        if (onSuccess) onSuccess();
    } else {
        if (onFailure) onFailure();
    }
};

const getMovementList = (dataProvider: DataProvider, movementList: Avatar) =>
    dataProvider.getList(AVATARS, {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'asc' },
        filter: {
            id: extractIdFromURI(movementList['@id']),
            serialization_groups: [
                'read_meta',
                'read_avatar_current_referencing',
                'read_avatar_current_referencing_nodes',
            ],
        },
    });
