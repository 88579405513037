import {Node} from "./types";
import moment from 'moment';

export const getNodes = (startNode: Node) => {
    let nodesTemp = [];
    let result = [];

    if (startNode.hasOwnProperty('isStartNodeBridgeToStartProcess')
        // @ts-ignore
        && startNode.isStartNodeBridgeToStartProcess === true) {
        startNode = Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ?
            startNode.nextNodes[0] : null;
    }

    if (startNode && startNode.hasOwnProperty('nextNodes')) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while(nodesTemp.length > 0){
        let node = nodesTemp.shift();
        // @ts-ignore
        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;

        if(nextNodes){
            nextNodes.forEach(nextN => {
                nodesTemp.push(nextN);
                result.push(nextN);
            });
        }
        else{
            nodesTemp = [];
        }
    }

    return result;
}