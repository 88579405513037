import { MOVEMENT_LIST_OPEN_STATE } from '../../services/utils/CONST';
import { decodeToken } from '../../services/utils/token';
import {
    ActionStructureAction,
    Avatar,
    AvatarProperty,
    FileSystemMapper,
    MC,
    TypeNote,
} from '../../services/utils/types';
import {
    resetResourcesActionCreator,
    resolvedContainerActionCreator,
    resolvedMcActionCreator,
    resolvedMovementListActionCreator,
    resolvedRefstockActionCreator,
    resolvedRefstockImage,
    resolvedRefstockPropertiesCreator,
    resolvedTokenWithRolesActionCreator,
    resolvedTypeNoteActionCreator,
    resolvedURIActionCreator,
} from '../actions/resourceActionCreator';

export interface ResourceState {
    mc: MC | null;
    refstock: Avatar | null;
    movementList: Avatar | null;
    uri: string | null;
    refstockProperties: AvatarProperty[];
    typeNote: TypeNote | null;
    container: Avatar | null;
    tokenWithRoles: {
        token: string | null;
        roles: string[];
    };
    refstockImage: FileSystemMapper | null;
}

export const InitialState: ResourceState = {
    mc: null,
    refstock: null,
    movementList: null,
    uri: null,
    refstockProperties: [],
    typeNote: null,
    container: null,
    tokenWithRoles: {
        token: null,
        roles: [],
    },
    refstockImage: null,
};

const reducer = (state: ResourceState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedMcActionCreator.action: {
            const { mc } = action.payload;
            return {
                ...state,
                mc,
            };
        }
        case resolvedRefstockActionCreator.action: {
            const { refstock } = action.payload;
            return {
                ...state,
                refstock,
            };
        }
        case resolvedContainerActionCreator.action: {
            const { container } = action.payload;
            return {
                ...state,
                container,
            };
        }
        case resolvedMovementListActionCreator.action: {
            let { movementList }: { movementList: Avatar | null } = action.payload;

            if (movementList?.currentReferencing.standardReferencing.runner.node.name !== MOVEMENT_LIST_OPEN_STATE) {
                movementList = null;
            }

            return {
                ...state,
                movementList,
            };
        }
        case resolvedURIActionCreator.action: {
            const { uri } = action.payload;
            return {
                ...state,
                uri,
            };
        }
        case resolvedRefstockPropertiesCreator.action: {
            const { properties } = action.payload;
            return {
                ...state,
                refstockProperties: properties,
            };
        }
        case resolvedTypeNoteActionCreator.action: {
            const { typeNote } = action.payload;
            return {
                ...state,
                typeNote,
            };
        }
        case resolvedTokenWithRolesActionCreator.action: {
            const { token } = action.payload;

            const decodedToken: any = token ? decodeToken(token) : null;
            const roles: string[] =
                decodedToken && (decodedToken.payload as object).hasOwnProperty('roles')
                    ? Object.values(decodedToken.payload.roles)
                    : [];

            return {
                ...state,
                tokenWithRoles: {
                    token,
                    roles,
                },
            };
        }
        case resolvedRefstockImage.action: {
            return {
                ...state,
                refstockImage: action.payload.record,
            };
        }
        case resetResourcesActionCreator.action: {
            return {
                ...state,
                mc: null,
                refstock: null,
                movementList: null,
                uri: null,
                refstockProperties: [],
                typeNote: null,
                container: null,
                refstockImage: null,
            };
        }
        default:
            return state ? state : InitialState;
    }
};

export default reducer;
