import { ActionStructure } from '../../services/utils/types';
import { EnqueueSnackbar } from '../reducers/uiReducer';

export const resolvedQuery: ActionStructure = {
    action: '360SC/RESOLVED_QUERY',
    fn: (query: { redirectionKey: string }) => ({
        type: resolvedQuery.action,
        payload: {
            query,
        },
    }),
};

export const enqueueSnackbar: ActionStructure = {
    action: '360/ENQUEUE_SNACKBAR',
    fn: (item: EnqueueSnackbar) => ({
        type: enqueueSnackbar.action,
        payload: {
            item,
        },
    }),
};

export const removeEnqueueSnackbar: ActionStructure = {
    action: '360/REMOVE_ENQUEUED_SNACKBAR',
    fn: (id: string) => ({
        type: removeEnqueueSnackbar.action,
        payload: {
            id,
        },
    }),
};

export const setLoadingStateToAction: ActionStructure = {
    action: '360/SET_LOADING_STATE',
    fn: (toState: boolean) => ({
        type: setLoadingStateToAction.action,
        payload: {
            toState,
        },
    }),
};
