import moment from 'moment';
import React from 'react';
import { MC } from '../services/utils/types';

export const capitalizeString = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const renderDangerousText = (text: string, props: any = {}, el: string = 'span') => {
    const styles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(props.hasOwnProperty('style') ? props['style'] : {}),
    };
    const mStyles = props.hasOwnProperty('style') && props['style'] ? props['style'] : styles;
    return React.createElement(el, {
        ...props,
        style: {
            ...mStyles,
        },
        dangerouslySetInnerHTML: { __html: text },
    });
};

export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  k:mm:ss') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

export const generateRandomId = (): string => Math.random().toString(36).substr(2, 9);

export const getName = (nameWithPrefix: string) => {
    if (nameWithPrefix) return nameWithPrefix.split('-prefix-').reverse()[0];
    return nameWithPrefix;
};

export const getShortFromFileText = (fileText: string) => {
    let mat = fileText.split('\n');
    let textValues = mat && mat.length > 1 ? mat[1] : null;
    let yourls = textValues && textValues.length > 0 ? textValues.split(',')[0] : null;
    return yourls && yourls.length > 0 ? yourls.split('/').reverse()[0] : null;
};

export const hasRoles = (roles: string[], rolesToCheck: string[]) => {
    let result = true;
    rolesToCheck.map((role) => {
        if (!roles.includes(role)) result = false;
    });

    return result;
};

export const hasAlmostOneRoles = (roles: string[], rolesToCheck: string[]) => {
    const index = roles.findIndex((role) => {
        const index = rolesToCheck.findIndex((roleToCheck) => {
            return roleToCheck === role;
        });

        return index !== -1;
    });

    return index !== -1;
};

export const getMcUri = (mc: string | MC) => (typeof mc === 'string' ? mc : mc['@id']);
