import {
    ActionStructureFetcher,
    Callbacks,
    DownloadFileSystemMapperAction,
    GetFileSystemMapperParams,
    GetOnFileSystemMapperAction,
    Identifier,
} from '../../services/utils/types';

export const getOneFileSystemMapperAction: ActionStructureFetcher = {
    action: '360SC/GET_ONE_FILE_SYSTEM_MAPPER',
    fn: (
        data: GetFileSystemMapperParams,
        meta: {
            onSuccess: () => void;
            onFailure: () => void;
        }
    ): GetOnFileSystemMapperAction => ({
        type: getOneFileSystemMapperAction.action,
        payload: {
            data,
        },
        meta,
    }),
};

export const downloadFileSystemMapperAction: ActionStructureFetcher = {
    action: '360SC/DOWNLOAD_FILE_SYSTEM_MAPPER',
    fn: (
        id: string,
        meta: {
            onSuccess: ({ data }: { data: string }) => void;
            onFailure: () => void;
        }
    ): DownloadFileSystemMapperAction => ({
        type: downloadFileSystemMapperAction.action,
        payload: {
            id,
        },
        meta,
    }),
};
