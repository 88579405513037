import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../redux/reducers';
import { getLoadingState } from '../../redux/selectors/loading';

interface Props {}

const BackdropLoading = ({}: Props) => {
    const loadingState: boolean = useSelector((state: GlobalState) => getLoadingState(state));

    return (
        <Backdrop sx={{ zIndex: 3000 }} open={loadingState} data-test-sc='backdropLoading'>
            <CircularProgress color='primary' />
        </Backdrop>
    );
};

export default BackdropLoading;
