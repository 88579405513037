import { fork, put, select, takeEvery } from 'redux-saga/effects';
import {
    checkContainerState,
    closeListOfMovementList,
    editAmountItem,
    sendEmailRequest,
    updateRefStockProcess,
} from '../../services/containerSecurityRequest';
import {
    Avatar,
    AvatarProperty,
    Company,
    DataProvider,
    EditRefStockCurrentAmountAction,
    TypeNote,
    UpdateRefStockProcessAction,
} from '../../services/utils/types';
import {
    closeListOfMovementListAction,
    editRefStockCurrentAmountAction,
    sendEmailAction,
    updateRefStockProcessAction,
    willMakeAnActionIntoTheContainerAction,
} from '../actions/containerSecurityActionCreator';
import { GlobalState } from '../reducers';
import { UserState } from '../reducers/userReducer';

const editRefStockCurrentAmountMiddleware = (dataProvider: DataProvider) =>
    function* ({
        payload: { amountAction, amount, actionDescription, currentPickupAmount, changeAmountProperty, howManyInStock },
        meta,
    }: EditRefStockCurrentAmountAction) {
        const refStock: Avatar = yield select((state: GlobalState) => state.resources.refstock);
        const refStockProperties: AvatarProperty[] = yield select(
            (state: GlobalState) => state.resources.refstockProperties
        );
        const movementList: Avatar = yield select((state: GlobalState) => state.resources.movementList);
        const typeNote: TypeNote = yield select((state: GlobalState) => state.resources.typeNote);

        yield fork(
            // @ts-ignore
            editAmountItem,
            dataProvider,
            actionDescription,
            amountAction,
            amount,
            currentPickupAmount,
            refStock,
            refStockProperties,
            movementList,
            typeNote,
            howManyInStock,
            changeAmountProperty,
            meta
        );
    };

const updateRefStockProcessMiddleware = (dataProvider: DataProvider) =>
    function* ({ payload: { refStock, refStockProperties, currentState }, meta }: UpdateRefStockProcessAction) {
        yield fork(updateRefStockProcess, dataProvider, refStock, refStockProperties, currentState, meta);
    };

const willMakeAnActionIntoTheContainerMiddleware = (dataProvider: DataProvider) =>
    function* ({
        payload: { container, actionDescription, onSuccess, onFailure },
    }: {
        payload: { container: Avatar; actionDescription: string; onSuccess: Function; onFailure: Function };
    }) {
        const refStock: Avatar = yield select((state: GlobalState) => state.resources.refstock);
        const movementList: Avatar = yield select((state: GlobalState) => state.resources.movementList);
        const typeNote: TypeNote = yield select((state: GlobalState) => state.resources.typeNote);
        const userState: UserState = yield select((state: GlobalState) => state.user);

        yield fork(
            checkContainerState,
            dataProvider,
            container,
            movementList,
            refStock,
            typeNote,
            actionDescription,
            userState,
            onSuccess,
            onFailure
        );
    };

const closeListOfMovementListActionMiddleware = (dataProvider: DataProvider) =>
    function* (action: {
        payload: { listOfMovementList: Avatar[] };
        meta: { onSuccess: Function; onEachRequest?: (movementList: Avatar) => void };
    }) {
        /**
         * When we are in Container view the "refStock" variable is the Container.
         */
        const avatar: Avatar = yield select((state: GlobalState) => state.resources.refstock);
        const userState: UserState = yield select((state: GlobalState) => state.user);

        yield fork(
            // @ts-ignore
            closeListOfMovementList,
            dataProvider,
            action.payload.listOfMovementList,
            avatar,
            avatar.company,
            userState,
            action.meta.onSuccess,
            action.meta.onEachRequest
        );
    };

const sendEmailMiddleware = (dataProvider: DataProvider) =>
    function* (action: {
        payload: {
            subject: string;
            addressesTo: string[];
            body: string;
            onSuccess: Function;
            onFailure: Function;
            attachments: { fileName: string; data: string }[];
        };
        meta: { onSuccess?: Function; onFailure?: Function };
    }) {
        yield fork(
            sendEmailRequest,
            dataProvider,
            action.payload.subject,
            action.payload.addressesTo,
            action.payload.body,
            action.meta.onSuccess,
            action.meta.onFailure,
            action.payload.attachments
        );
    };

export default (dataProvider: DataProvider) => {
    return function* runRequest() {
        yield takeEvery(editRefStockCurrentAmountAction.action, editRefStockCurrentAmountMiddleware(dataProvider));
        yield takeEvery(updateRefStockProcessAction.action, updateRefStockProcessMiddleware(dataProvider));
        yield takeEvery(
            // @ts-ignore
            willMakeAnActionIntoTheContainerAction.action,
            willMakeAnActionIntoTheContainerMiddleware(dataProvider)
        );
        // @ts-ignore
        yield takeEvery(closeListOfMovementListAction.action, closeListOfMovementListActionMiddleware(dataProvider));
        // @ts-ignore
        yield takeEvery(sendEmailAction.action, sendEmailMiddleware(dataProvider));
    };
};
