import { NextRouter } from 'next/router';
import qs from 'qs';
import { CREATE_AVATAR_PAGE, MAIN_PAGE, LEVEL_PAGE } from './CONST';

const assetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX;
const rootAddress = process.env.NEXT_PUBLIC_ROOT_ADDRESS;

export const routeWithParamAs = (router: NextRouter, url: string) => {
    router.push(url, `${rootAddress}${assetPrefix}${url}`, {
        shallow: true,
    });
};

export const routeReplaceWithParamAs = (router: NextRouter, url: string) => {
    router.replace(url, `${rootAddress}${assetPrefix}${url}`, {
        shallow: true,
    });
};

export const redirectToUsingMode = (mode: string | null | undefined, query: { [key: string]: string } = {}) => {
    if (!mode) return `${LEVEL_PAGE}?${qs.stringify(query)}`;

    const splits = mode.split('.');

    let page = LEVEL_PAGE;
    // switch (splits[0]) {
    //     case CREATE_AVATAR_PAGE.substr(1):
    //         page = CREATE_AVATAR_PAGE;
    //         break;
    //     case LOCAL_PAGE.substr(1):
    //         page = LOCAL_PAGE;
    //         break;
    //     default:
    //         break;
    // }

    return `${page}?${qs.stringify(query)}`;
};

export const getRedirectionKeyFromMCUrl = (mcUrl: string): string | null => {
    const splits = mcUrl ? mcUrl.split('/') : null;
    return splits && splits.length > 0 ? splits[splits.length - 1] : null;
};
