import { CacheProvider } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { NextIntlProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import { NextRouter, useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BackdropLoading from '../components/backdrop-loading/backdrop-loading';
import createEmotionCache from '../components/createEmotionCache';
import HeadConf from '../components/head-conf/head-conf';
import { GlobalState } from '../redux/reducers';
import { TokenData } from '../redux/reducers/userReducer';
import { getTokenData } from '../redux/selectors/user';
import { wrapper } from '../redux/store';
import { ROOT } from '../services/utils/CONST';
import { routeWithParamAs } from '../services/utils/route';
import mainTheme from '../themes/main-theme';

const clientSideEmotionCache = createEmotionCache();

const CustomIconButton = styled(IconButton)({
    color: 'white',
});

// @ts-ignore
const MyApp = ({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppProps) => {
    const router: NextRouter = useRouter();
    const tokenData: TokenData | null = useSelector((state: GlobalState) => getTokenData(state));

    const notistackRef = React.createRef();

    const onClickDismiss = (key: any) => () => {
        // @ts-ignore
        notistackRef.current.closeSnackbar(key);
    };

    useEffect(() => {
        if (!tokenData) {
            routeWithParamAs(router, `${ROOT}?${qs.stringify(router.query)}`);
        }
    }, []);

    return (
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={mainTheme}>
                <SnackbarProvider
                    hideIconVariant
                    maxSnack={3}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                    }}
                    autoHideDuration={3000}
                    // @ts-ignore
                    ref={notistackRef}
                    action={(key) => (
                        <CustomIconButton onClick={onClickDismiss(key)}>
                            <CloseIcon style={{ fontSize: 20 }} />
                        </CustomIconButton>
                    )}
                >
                    <NextIntlProvider messages={pageProps.messages} locale={'en'}>
                        <HeadConf title='Container Security' />
                        <CssBaseline />
                        <Component {...pageProps} />
                        <BackdropLoading />
                    </NextIntlProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};

export default wrapper.withRedux(MyApp);
