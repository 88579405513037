import { OptionsObject, SnackbarMessage } from 'notistack';
import { ActionStructureAction } from '../../services/utils/types';
import {
    enqueueSnackbar,
    removeEnqueueSnackbar,
    resolvedQuery,
    setLoadingStateToAction,
} from '../actions/uiActionCreator';

export interface EnqueueSnackbar {
    id: string;
    translateMessage: boolean;
    message: SnackbarMessage;
    option?: OptionsObject;
}

export interface UiState {
    url: {
        query: {
            redirectionKey: string | null;
        };
    };
    enqueueSnackbar: EnqueueSnackbar[];
    loadingState: boolean;
}

const InitialState: UiState = {
    url: {
        query: {
            redirectionKey: null,
        },
    },
    enqueueSnackbar: [],
    loadingState: false,
};

export default (state: UiState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedQuery.action: {
            return {
                ...state,
                url: {
                    ...state.url,
                    query: {
                        ...state.url.query,
                        ...action.payload.query,
                    },
                },
            };
        }
        case enqueueSnackbar.action: {
            return {
                ...state,
                enqueueSnackbar: [...state.enqueueSnackbar, action.payload.item],
            };
        }
        case removeEnqueueSnackbar.action: {
            return {
                ...state,
                enqueueSnackbar: [...state.enqueueSnackbar.filter((item) => item.id !== action.payload.id)],
            };
        }
        case setLoadingStateToAction.action: {
            const { toState } = action.payload;
            return {
                ...state,
                loadingState: toState,
            };
        }
        default:
            return state;
    }
};
